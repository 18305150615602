import { IApi } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { ok, ResultAsync, err } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { RentOrder } from '@/shared/interfaces/rentOrder';
import { RentOrderInput, Void } from '@/shared/graphql/graphql';

export class RentOrderService implements RentOrder {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async createOrder(order: RentOrderInput): ResultAsync<Void, IGraphQLError> {
    const createRentOrderMutation = graphql(`
      mutation createOrder($input: RentOrderInput!) {
        createRentOrder(input: $input) {
          void
        }
      }
    `);

    const response = await this.graphql.mutate(createRentOrderMutation, { input: order });

    if (response.isErr()) {
      return err(response.error);
    }
    return ok(null as unknown as Void);
  }
}
