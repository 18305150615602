import { RentHolidays } from '@/shared/interfaces/rentHolidays';
import { Logger } from '@/shared/services/logger';
import { ok, ResultAsync } from '@/shared/utils/result';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { PaginatedResource } from '@/shared/interfaces/pagination';
import { graphql } from '@/shared/graphql/gql';
import { PublicHoliday } from '@/shared/graphql/graphql';
import { MARKET, PUBLIC_HOLIDAYS_LIMIT } from '@/shared/constants/mockRentMarket';

export class RentHolidaysService implements RentHolidays {
  graphql: IGgraphQL;

  logger: Logger;

  constructor(graphQLClient: IGgraphQL, logger: Logger) {
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async getRentHolidays(): ResultAsync<PaginatedResource<PublicHoliday>, IGraphQLError> {
    const variables = {
      countryCode: MARKET,
      limit: PUBLIC_HOLIDAYS_LIMIT,
      page: 1,
    };

    const getRentHolidaysQuery = graphql(`
      query getPublicHolidays($countryCode: String!, $limit: Int, $page: Int) {
        publicHolidays(countryCode: $countryCode, limit: $limit, page: $page) {
          items {
            date
            id
            localName
            name
          }
          meta {
            totalItems
            current
            last
            itemsPerPage
          }
        }
      }
    `);

    const response = await this.graphql.query(getRentHolidaysQuery, variables);

    return ok(response.publicHolidays as PaginatedResource<PublicHoliday>);
  }
}
