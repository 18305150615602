import { computed } from 'vue';
import { IApi, ViolationErrorMap } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { ok, ResultAsync } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { IGgraphQL } from '@/shared/interfaces/graphql';
import { RentOrders } from '@/shared/interfaces/rentOrders';
import { ApiRentOrder } from '@/shared/interfaces/api/ApiRentOrder';
import { DataQueryParams, PaginatedResource } from '@/shared/interfaces/pagination';

const DEFAULT_PAGE_SIZE = 20;

export class RentOrdersService implements RentOrders {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async getOrders(
    variables: DataQueryParams
  ): ResultAsync<PaginatedResource<ApiRentOrder>, ViolationErrorMap> {
    const extendedVariables = computed(() => ({
      limit: DEFAULT_PAGE_SIZE,
      ...variables,
    }));

    const getOrdersQuery = graphql(`
      query getRentOrders(
        $limit: Int
        $page: Int
        $branches: [Int]
        $rent: DateInterval
        $status: RentOrderStatusEnum
        $search: String
      ) {
        rentOrders(
          limit: $limit
          page: $page
          branches: $branches
          rent: $rent
          status: $status
          search: $search
        ) {
          items {
            id
            internalId
            rent
            companyName
            discountPercentage
            priceTotal {
              amount
              amountInCents
              currency
            }
            sourceSystem
            status
            invoiceStatus
            products {
              rentOrderProduct {
                id
                productId
                serializedItemId
                branch
                rent
              }
              rentOrderProductDetails {
                name
                serializedItemNumber
                quantity
                categories {
                  id
                  code
                  name
                  parent {
                    id
                    code
                    name
                    parent {
                      id
                      code
                      name
                      parent {
                        id
                        code
                        name
                      }
                    }
                  }
                }
                singleDayPrice {
                  amount
                  amountInCents
                  currency
                }
                status
                images {
                  type
                  path
                }
              }
            }
          }
          meta {
            current
            itemsPerPage
            last
            totalItems
          }
        }
      }
    `);

    const response = await this.graphql.query(getOrdersQuery, extendedVariables.value);

    return ok({
      items: response.rentOrders?.items,
      ...response.rentOrders?.meta,
    } as PaginatedResource<ApiRentOrder>);
  }
}
