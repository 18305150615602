import { IApi } from '@/shared/interfaces/api';
import { Logger } from '@/shared/services/logger';
import { ok, ResultAsync } from '@/shared/utils/result';
import { graphql } from '@/shared/graphql/gql';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import {
  RentSerializedItems,
  RentSerializedItemsOptions,
} from '@/shared/interfaces/rentSerializedItems';
import { ApiSerializedItem } from '@/shared/interfaces/api/ApiSerializedItem';

export class RentSerializedItemsService implements RentSerializedItems {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async getSerializedItems(
    options: RentSerializedItemsOptions
  ): ResultAsync<ApiSerializedItem[], IGraphQLError> {
    const getSerializedItemsQuery = graphql(`
      query getSerializedItems(
        $productId: Uuid!
        $display: DateInterval!
        $rent: DateInterval
        $buffer: DateInterval
      ) {
        rentSerializedItems(
          productId: $productId
          display: $display
          rent: $rent
          buffer: $buffer
        ) {
          items {
            id
            name
            available
            items {
              id
              available
              reservations {
                id
                type
                period
                buffer
              }
            }
          }
        }
      }
    `);

    const response = await this.graphql.query(getSerializedItemsQuery, options);

    return ok(response.rentSerializedItems?.items as ApiSerializedItem[]);
  }
}
