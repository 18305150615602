export const DEFAULT_COUNTRY = 'DE';

export const COUNTRIES = {
  DE: 'DE' as const,
  PL: 'PL' as const,
};

export const COUNTRY_TRANSLATIONS = {
  DE: 'shared_country_de',
  PL: 'shared_country_pl',
};

export const GERMAN_TAX = 19;

export type Locales = keyof typeof COUNTRIES;
