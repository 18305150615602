import { DataQueryParams, PaginatedResource } from '@/shared/interfaces/pagination';
import { Logger } from '@/shared/services/logger';
import { API_LINK } from '@/shared/constants/apiLinks';
import { IApi, ViolationErrorMap } from '@/shared/interfaces/api';
import { ApiCompanyShipmentRecipient } from '@/shared/interfaces/api/ApiCompanyShipmentRecipient';
import { Clients } from '@/pages/client/interfaces/clients';
import { ApiCompanyLocation } from '@/shared/interfaces/api/ApiCompanyLocation';
import { ApiCompany } from '@/shared/interfaces/api/ApiCompany';
import { ApiCreditLimit } from '@/shared/interfaces/api/ApiCreditLimit';
import { CompanyEditForm } from '@/shared/interfaces/forms/CompanyEditForm';
import { err, ok, ResultAsync } from '@/shared/utils/result';
import { ApiRemoteCustomer } from '@/shared/interfaces/api/ApiRemoteCustomer';
import { CompanyContactForm } from '@/shared/interfaces/forms/CompanyContactForm';
import { CompanyLocationEditForm } from '@/shared/interfaces/forms/CompanyLocationForm';
import { COMPANY_LOCATIONS_FILTERS } from '@/pages/client/constants/companyLocationsFilters';
import { ApiOrdersSummary } from '@/shared/interfaces/api/ApiOrdersSummary';
import { OutputChannelForm } from '@/shared/interfaces/forms/OutputChannelForm';
import { OutputChannelRecipientForm } from '@/shared/interfaces/forms/OutputChannelRecipientForm';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { graphql } from '@/shared/graphql/gql';
import {
  AuditLogEventEnum,
  AuditLogItems,
  ChangeCustomerEmailMutation,
} from '@/shared/graphql/graphql';
import { ApiDiscountGroup } from '@/shared/interfaces/api/ApiDiscountGroup';

export class ClientsService implements Clients {
  api: IApi;

  graphql: IGgraphQL;

  logger: Logger;

  constructor(api: IApi, graphQLClient: IGgraphQL, logger: Logger) {
    this.api = api;
    this.graphql = graphQLClient;
    this.logger = logger;
  }

  async getClients(
    options: DataQueryParams,
    useAbortController = false
  ): ResultAsync<PaginatedResource<ApiCompany>, ViolationErrorMap> {
    const response = await this.api.getPaginatedResource<ApiCompany>(
      API_LINK.companies,
      options,
      useAbortController
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value);
  }

  async getVerifiedClients(
    options: DataQueryParams,
    useAbortController = false
  ): ResultAsync<PaginatedResource<ApiCompany>, ViolationErrorMap> {
    const response = await this.api.getPaginatedResource<ApiCompany>(
      (params) => {
        params.append('eshopStatus[]', 'imported');
        params.append('eshopStatus[]', 'invited');
        params.append('eshopStatus[]', 'active');
        return API_LINK.companies(params);
      },
      options,
      useAbortController
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value);
  }

  async getImportedClients(
    options: DataQueryParams
  ): ResultAsync<PaginatedResource<ApiRemoteCustomer>, ViolationErrorMap> {
    const response = await this.api.getPaginatedResource<ApiRemoteCustomer>(
      API_LINK.remoteCustomers,
      options,
      true
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value);
  }

  async getImportedClient(id: string): ResultAsync<ApiRemoteCustomer, ViolationErrorMap> {
    const response = await this.api.get<ApiRemoteCustomer>(API_LINK.remoteCustomer(id));
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async editClient(id: string, form: CompanyEditForm): ResultAsync<ApiCompany, ViolationErrorMap> {
    const response = await this.api.put<ApiCompany>(API_LINK.companyV2(id), {
      body: JSON.stringify(form),
    });
    if (response.isOk()) {
      return ok(response.value.data);
    }
    const { data } = response.error;
    return err(data);
  }

  async getClient(companyId: string): ResultAsync<ApiCompany, ViolationErrorMap> {
    const response = await this.api.get<ApiCompany>(API_LINK.company(companyId));
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async getClientCreditLimit(companyId: string): ResultAsync<ApiCreditLimit, ViolationErrorMap> {
    const response = await this.api.get<ApiCreditLimit>(API_LINK.companyCreditLimit(companyId));
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async getClientOrderSummary(companyId: string): ResultAsync<ApiOrdersSummary, ViolationErrorMap> {
    const response = await this.api.get<ApiOrdersSummary>(API_LINK.companyOrderSummary(companyId));
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async getClientAddresses(
    companyId: string,
    options: DataQueryParams
  ): ResultAsync<PaginatedResource<ApiCompanyLocation>, ViolationErrorMap> {
    const response = await this.api.getPaginatedResource<ApiCompanyLocation>(
      (params) => API_LINK.companyLocations(companyId, params),
      options,
      true
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value);
  }

  async saveClientAddress(
    id: string,
    form: CompanyLocationEditForm
  ): ResultAsync<ApiCompanyLocation, ViolationErrorMap> {
    const response = await this.api.post<ApiCompanyLocation>(API_LINK.companyLocations(id), {
      body: JSON.stringify(form),
    });
    if (response.isOk()) {
      return ok(response.value.data);
    }
    const { data } = response.error;
    return err(data);
  }

  async editClientAddress(
    id: string,
    form: CompanyLocationEditForm
  ): ResultAsync<ApiCompanyLocation, ViolationErrorMap> {
    const response = await this.api.put<ApiCompanyLocation>(API_LINK.location(id), {
      body: JSON.stringify(form),
    });
    if (response.isOk()) {
      return ok(response.value.data);
    }
    const { data } = response.error;
    return err(data);
  }

  async deleteClientAddress(id: string): ResultAsync<unknown, ViolationErrorMap> {
    const response = await this.api.delete(API_LINK.location(id));
    if (response.isOk()) {
      return ok(undefined);
    }
    const { data } = response.error;
    return err(data);
  }

  async getEmployee(id: string): ResultAsync<ApiCompanyShipmentRecipient, ViolationErrorMap> {
    const response = await this.api.get<ApiCompanyShipmentRecipient>(API_LINK.employee(id));
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data);
  }

  async saveCompanyContact(
    companyId: string,
    form: CompanyContactForm
  ): ResultAsync<ApiCompanyShipmentRecipient, ViolationErrorMap> {
    const response = await this.api.post<ApiCompanyShipmentRecipient>(
      API_LINK.companyEmployees(companyId),
      {
        body: JSON.stringify(form),
      }
    );
    if (response.isOk()) {
      return ok(response.value.data);
    }
    const { data } = response.error;
    return err(data);
  }

  // remove with fe_5382_new_contact_edit_endpoint_enabled
  async editCompanyContact(
    companyId: string,
    contactId: string,
    form: CompanyContactForm
  ): ResultAsync<ApiCompanyShipmentRecipient, ViolationErrorMap> {
    const response = await this.api.put<ApiCompanyShipmentRecipient>(
      API_LINK.companyContactEdit(companyId, contactId),
      {
        body: JSON.stringify(form),
      }
    );
    if (response.isOk()) {
      return ok(response.value.data);
    }
    const { data } = response.error;
    return err(data);
  }

  async editContact(
    contactId: string,
    form: CompanyContactForm
  ): ResultAsync<ApiCompanyShipmentRecipient, ViolationErrorMap> {
    const response = await this.api.put<ApiCompanyShipmentRecipient>(
      API_LINK.contactEdit(contactId),
      {
        body: JSON.stringify(form),
      }
    );
    if (response.isOk()) {
      return ok(response.value.data);
    }
    const { data } = response.error;
    return err(data);
  }

  async deleteCompanyContact(contactId: string): ResultAsync<unknown, ViolationErrorMap> {
    const response = await this.api.delete(API_LINK.employee(contactId));
    if (response.isOk()) {
      return ok(undefined);
    }
    const { data } = response.error;
    return err(data);
  }

  async getCompanyLocations(
    id: string,
    options: DataQueryParams,
    typeFilter: keyof typeof COMPANY_LOCATIONS_FILTERS | null = null
  ): ResultAsync<PaginatedResource<ApiCompanyLocation>, ViolationErrorMap> {
    const optionWithSearchKey = {
      ...options,
      searchKey: 'search',
      ...(typeFilter ? { [typeFilter]: COMPANY_LOCATIONS_FILTERS[typeFilter] } : {}),
    };
    const response = await this.api.getPaginatedResource<ApiCompanyLocation>(
      (params) => API_LINK.companyLocations(id, params),
      optionWithSearchKey
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value);
  }

  async getCompanyEmployees(
    companyId: string,
    options: DataQueryParams
  ): ResultAsync<PaginatedResource<ApiCompanyShipmentRecipient>, ViolationErrorMap> {
    const optionsWithSearchKey = { ...options, searchKey: 'search' };
    const response = await this.api.getPaginatedResource<ApiCompanyShipmentRecipient>(
      (params) => API_LINK.companyEmployees(companyId, params),
      optionsWithSearchKey
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value);
  }

  // TODO: this might be related to getCompanyEmployees, but it does not match ApiCompanyContact Schema
  async getCompanyContacts(
    companyId: string,
    options: DataQueryParams
  ): ResultAsync<PaginatedResource<ApiCompanyShipmentRecipient>, ViolationErrorMap> {
    const optionsWithSearchKey = { ...options, searchKey: 'search' };
    const response = await this.api.getPaginatedResource<ApiCompanyShipmentRecipient>(
      (params) => API_LINK.companyEmployees(companyId, params),
      optionsWithSearchKey,
      true
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value);
  }

  async getCompanyLocationEmployees(
    locationId: string,
    options: DataQueryParams
  ): ResultAsync<PaginatedResource<ApiCompanyShipmentRecipient>, ViolationErrorMap> {
    const optionsWithSearchKey = { ...options, searchKey: 'search' };
    const response = await this.api.getPaginatedResource<ApiCompanyShipmentRecipient>(
      (params) => API_LINK.locationEmployees(locationId, params),
      optionsWithSearchKey
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value);
  }

  async editOutputChannel(
    companyId: string,
    form: OutputChannelForm
  ): ResultAsync<ApiCompany, ViolationErrorMap> {
    const response = await this.api.put<ApiCompany>(API_LINK.companyOutputChannels(companyId), {
      body: JSON.stringify(form),
    });
    if (response.isOk()) {
      return ok(response.value.data);
    }
    const { data } = response.error;
    return err(data);
  }

  async editOutputChannelRecipient(
    companyId: string,
    form: OutputChannelRecipientForm
  ): ResultAsync<ApiCompany, ViolationErrorMap> {
    const response = await this.api.patch<ApiCompany>(API_LINK.companyOutputChannels(companyId), {
      body: JSON.stringify(form),
    });
    if (response.isOk()) {
      return ok(response.value.data);
    }
    const { data } = response.error;
    return err(data);
  }

  async editLoginEmail(
    id: string,
    newEmail: string
  ): ResultAsync<
    ChangeCustomerEmailMutation['changeCustomerEmail'] | undefined | null,
    IGraphQLError
  > {
    const editLoginEmailMutation = graphql(`
      mutation ChangeCustomerEmail($input: ChangeEmailInput!) {
        changeCustomerEmail(input: $input) {
          id
          email
          phone
        }
      }
    `);
    const response = await this.graphql.mutate(editLoginEmailMutation, {
      input: {
        customerId: id,
        newEmail,
      },
    });
    if (response.isOk()) {
      return ok(response.value?.changeCustomerEmail);
    }
    return err(response.error);
  }

  async getDiscountGroups(): ResultAsync<ApiDiscountGroup[], ViolationErrorMap> {
    const response = await this.api.get<PaginatedResource<ApiDiscountGroup>>(
      API_LINK.discountGroups
    );
    if (response.isErr()) {
      const { data } = response.error;
      return err(data);
    }
    return ok(response.value.data.items);
  }

  async getAuditLogs(customerId: string): ResultAsync<AuditLogItems['items'], IGraphQLError> {
    const getAuditsQuery = graphql(`
      query AuditLogsQuery($customerId: Uuid, $event: AuditLogEventEnum) {
        auditLogs(customerId: $customerId, event: $event) {
          items {
            oldValue
            newValue
            id
            createdAt
          }
        }
      }
    `);

    const response = await this.graphql.query(
      getAuditsQuery,
      {
        customerId,
        event: AuditLogEventEnum.CredentialsChange,
      },
      'network-only'
    );
    return ok(response.auditLogs?.items as AuditLogItems['items']);
  }
}
