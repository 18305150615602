/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloClient,
  FetchPolicy,
  InMemoryCache,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import { Logger } from '@/shared/services/logger';
import { IGgraphQL, IGraphQLError } from '@/shared/interfaces/graphql';
import { Auth } from '@/shared/interfaces/auth';
import { err, ok, ResultAsync } from '@/shared/utils/result';
import { toErrorsObject } from '@/shared/utils/fieldErrors';

interface Config {
  apiUrl: string;
  auth: Auth;
}

export class GraphQL implements IGgraphQL {
  client: ApolloClient<unknown>;

  config: Config;

  logger: Logger;

  constructor(config: Config, logger: Logger) {
    this.config = config;
    this.logger = logger;
    this.client = new ApolloClient({
      uri: config.apiUrl,
      cache: new InMemoryCache({
        addTypename: false,
      }),
      headers: {
        'accept-language': 'de_DE',
      },
    });
  }

  async query<T, Q extends OperationVariables>(
    query: TypedDocumentNode<T, Q>,
    variables: Q,
    fetchPolicy: FetchPolicy = 'cache-first'
  ): Promise<T> {
    const authHeaders = await this.getAuthHeaders();
    const result = await this.client.query({
      query,
      variables,
      fetchPolicy,
      context: {
        headers: { ...authHeaders },
      },
    });
    return result.data;
  }

  async mutate<T, Q extends OperationVariables>(
    mutation: TypedDocumentNode<T, Q>,
    variables: Q
  ): ResultAsync<T | null | undefined, IGraphQLError> {
    try {
      const authHeaders = await this.getAuthHeaders();
      const result = await this.client.mutate({
        mutation,
        variables,
        context: {
          headers: { ...authHeaders },
        },
        errorPolicy: 'all',
      });
      if (result.errors) {
        this.logger.error('Error fetching graphql', result.errors?.[0], {
          params: variables,
        });
        // TODO: figure out how to handle errors
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((result.errors?.[0] as any)?.errors) {
          return err({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: toErrorsObject((result.errors[0] as any).errors),
            violations: true,
          });
        }
        return err({ error: result.errors[0], violations: false });
      }
      return ok(result.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return err({ error, violations: false });
    }
  }

  async getAuthHeaders() {
    const { auth } = this.config;
    if (auth && !auth.isAuthenticated()) {
      await auth.callRefresh();
    }
    return {
      ...(auth ? { Authorization: `Bearer ${auth.getAuthToken()}` } : {}),
    };
  }
}
