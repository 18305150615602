import { Config } from '@/shared/interfaces/config';

export const getConfig = (): Config => ({
  apiBaseUrl: process.env.VUE_APP_API_URL ?? '',
  apiUrl: `${process.env.VUE_APP_API_URL}/api` ?? '',
  apiEmployeeUrl: `${process.env.VUE_APP_API_URL}/api/employee` ?? '',
  apiGraphQLUrl: `${process.env.VUE_APP_API_URL}/graphql/` ?? '',
  apiAuthUrl: process.env.VUE_APP_API_AUTH_URL ?? '',
  googleApiKey: process.env.VUE_APP_GOOGLE_API_KEY ?? '',
  currency: 'EUR',
  locale: 'de-DE',
});
