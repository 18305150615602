const q = (params?: URLSearchParams) => (params ? `?${params}` : '');
const parent = (id?: string) => (id ? `?parent=${id}` : '');
const category = (id?: string) => (id ? `?category=${id}` : '');

export const API_LINK = {
  companies: (params?: URLSearchParams): string => `/companies${q(params)}`,
  companiesV2: (params?: URLSearchParams): string => `/v2/companies${q(params)}`,
  companyV2: (id: string): string => `/v2/companies/${id}`,
  companySapOrders: (id: string, params?: URLSearchParams): string =>
    `/v1/companies/${id}/order-history${q(params)}`,
  sapOrder: (uuid: string): string => `/v1/order-history/${uuid}`,
  orderHistory: (params?: URLSearchParams): string => `/v1/order-history${q(params)}`,
  orderDetails: (id: string): string => `/v2/order-history/${id}`,
  companyOrderSummary: (id: string): string => `/v1/companies/${id}/orders/summary`,
  resendInvitation: (id: string): string => `/remote-customers/${id}/send-email`,
  resendInvitationV1: (id: string): string => `/v1/companies/${id}/resend-invite`,
  company: (id: string): string => `/companies/${id}`,
  companyCreditLimit: (id: string) => `/companies/${id}/credit-limit`,
  companyLocations: (id: string, params?: URLSearchParams): string =>
    `/companies/${id}/locations${q(params)}`,
  companyOutputChannels: (id: string) => `/v1/companies/${id}/output-channels`,
  location: (id: string): string => `/locations/${id}`,
  companyEmployees: (id: string, params?: URLSearchParams): string =>
    `/companies/${id}/contacts${q(params)}`,
  companyValidate: '/companies/validate-input',
  branches: '/branches',
  autocompleteCompany: (query: string): string => `/smart-signup/company?query=${query}`,
  ordersV2: (params?: URLSearchParams): string => `/v2/orders${q(params)}`,
  orderTotalsCheck: '/v1/order-totals',
  employee: (id: string): string => `/contacts/${id}`,
  employees: (params?: URLSearchParams): string => `/employees${q(params)}`,
  // remove companyContactEdit with fe_5382_new_contact_edit_endpoint_enabled
  companyContactEdit: (companyId: string, contactId: string): string =>
    `/companies/${companyId}/contacts/${contactId}`,
  contactEdit: (contactId: string): string => `/companies/contacts/${contactId}`,
  locationEmployees: (id: string, params?: URLSearchParams): string =>
    `/locations/${id}/contacts${q(params)}`,
  products: (params?: URLSearchParams): string => `/products${q(params)}`,
  product: (id: string): string => `/products/${id}`,
  remoteCustomers: (params?: URLSearchParams): string => `/remote-customers${q(params)}`,
  remoteCustomer: (id: string): string => `/remote-customers/${id}`,
  remoteCustomerCompanies: (id: string) => `/remote-customers/${id}/companies`,
  remoteCustomerCompaniesV2: (id: string) => `/v2/remote-customers/${id}/companies`,
  remoteCustomerInvite: (id: string) => `/v2/companies/${id}/invite`,
  uniqueContactPerson: (id: string) => `/companies/${id}/unique-contact-person`,
  clickAndCollect: '/orders/click-and-collect',

  discount: (routeWithId: string): string => `${routeWithId}`,
  discountGroups: '/discount-groups',

  // Uses rootApiClient as they have different bases
  categories: '/employee/v1/categories',
  subCategories: (id: string) => `/employee/categories/${id}/subcategories`,
  me: '/me',
  rentCategories: (id?: string) => `/rent/v1/categories${parent(id)}`,
  rentProducts: (id?: string): string => `/rent/v1/products${category(id)}`,
  rentProduct: (id: string) => `/rent/v1/products/${id}`,
  rentHolidays: () => `/rent/v1/public-holiday`,
  rentAvailability: `/rent/v1/equipment-availability`,
  rentOrder: '/rent/v1/orders',
  rentTotals: '/rent/v1/check-price',
  rentRate: '/rent/v1/check-rate',

  // Uses authorizedAuthClient
  roles: '/roles',
  users: (params?: URLSearchParams) => `/employees${q(params)}`,
  user: (id: string) => `/employees/${id}`,
};
